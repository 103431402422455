import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from "@/store/modules/alert";
import { loader } from "@/store/modules/loader";
import { account } from "@/store/modules/account";
import { quotation } from "@/store/modules/quotation";
import { upload } from "@/store/modules/upload";
import { member } from "@/store/modules/member";
import { clients } from "@/store/modules/clients";
import { notification } from "@/store/modules/notification";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    loader,
    account,
    quotation,
    upload,
    member,
    clients,
    notification
  },
});