export const Role = {
  SUPER: "super",
  REVIEW: "review",
  ACCEPTED: "accepted",
  PROCESSING: "processing",
  DELIVERED: "delivered",
  REVISION: "revision",
  COMPLETED: "completed",
  TRASH: "trash",
  CLIENTS: "clients",
  CLIENT: "client"
}

export const QuotaionStatus = {
  status: "status",
  requested: "requested",
  reviewed: "reviewed",
  accepted: "accepted",
  processing: "processing",
  delivered: "delivered",
  revision: "revision",
  completed: "completed",
  rejected: "rejected",
  deleted: "deleted"
}
