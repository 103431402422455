import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import tools from '@/utils/tools.js'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import DatetimePicker from 'vuetify-datetime-picker'
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad)

// add vuetify phone input
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(DatetimePicker)

// add custom tools
Vue.use({
  install(){
    Vue.tools = tools
    Vue.prototype.$tools = tools
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
