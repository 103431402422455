export default {
  getStorageName(name){
    return this.getEnv('VUE_APP_STROAGE_BASE') +'_'+ (name ? name : 'dummy');
  },
  parseJwt (token){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = Buffer.from(base64, 'base64').toString();
    return JSON.parse(jsonPayload);
  },
  authorize (auth = []){
    let parsedJWTJson = this.parseJwt(this.getSession("user").access_token);
    const scope = parsedJWTJson["scope"];
    const intersection = scope.filter(value => auth.includes(value));
    if (auth.length && intersection.length == 0){
      return false;
    }
    return true;
  },
  getLoggedInUserInfo(){
    const currentUser = this.getSession('user');
    return this.parseJwt(currentUser.access_token);
  },
  getFtpUser(){
    const currentUser = this.getSession('user');
    const jwtJson = this.parseJwt(currentUser.access_token);
    return jwtJson.sub.replaceAll('-', '');
  },
  getChatUser(){
    const currentUser = this.getSession('user');
    const jwtJson = this.parseJwt(currentUser.access_token);
    let chat_json = this.getSession('chat');
    
    let currentUserId = "";
    let currentUserName = "";
    if(jwtJson["cid"] != null){
      currentUserId = jwtJson["cid"];
      currentUserName = jwtJson["act"];
    } else if(chat_json){
      currentUserId = chat_json.id;
    }
    return {
      id: currentUserId,
      user: currentUserName,
    }
  },
  defaultImage(){
    return require('@/assets/images/avatars/1.png');
  },
  timeToNextToken(){
    let now = new Date();
    let expiresAt = new Date(this.getSession("user").expires_at);
    return (expiresAt - now) / 1000;
  },
  floatNum(val, len=2){
    return parseFloat((val?val:0)).toFixed(len);
  },
  capitalizeFirstLetter(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  isKeyInObjArr(array, key){
    return array.some(obj => obj.hasOwnProperty(key)); 
  },
  getValFromObjArr(objArr, key){
    var existingObj = objArr.find(function(element) {
      return typeof element[key] !== 'undefined';
    });
    return existingObj ? existingObj[key] : false;
  },
  formatDateTime(datetime, format){
    var date = new Date();
    if(datetime){
      date = new Date(datetime.toLocaleString());
    }
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var months = date.getMonth()+1;
    months = months < 10 ? '0'+months : months;
    var dates = date.getDate();
    dates = dates < 10 ? '0'+dates : dates;
    
    if(format=="24"){
      hours = hours % 24;
      hours = hours ? hours : 24; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ':' + seconds;
    } else{
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    }
    return date.getFullYear() + "-" + months + "-" + dates + " at " + strTime;
  },
  dateTimeToStr(datetime, format){
    var date = new Date();
    if(datetime){
      if(typeof datetime === 'object'){
        datetime = datetime.toString()
      }
      date = new Date(datetime);
    }
    var z = {
      M: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds()
    };
    format = format.replace(/(M+|d+|h+|m+|s+)/g, function(v){
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    });
  
    return format.replace(/(y+)/g, function(v){
      return date.getFullYear().toString().slice(-v.length)
    });
  },
  pastDate(past){
    const date = new Date();
    date.setDate(date.getDate() - past);

    return [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getDate().toString().padStart(2, '0'),
    ].join('-');
  },
  uuid(length=12){
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    
    for( var i = 0; i < length; i++ ){
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
    return result;
  },
  getFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  },
  addLeadingZeros(num, totalLength=6) {
    return String(num).padStart(totalLength, '0');
  },
  addCurrencySymbol(curr, sym="$"){
    if(curr == null || curr == 0 || curr == "" || curr == undefined){
      return "";
    }
    return `${sym}${curr}`;
  },
  getEnv(name){
    if(name.indexOf('VUE_APP_') == -1){
      name = 'VUE_APP_' + name
    }
    return process.env[name];
  },
  defaultSetting(){
    let settings = this.getLocal('settings');
    if(settings) return settings;
    
    this.setLocal('settings', {
      theme: {str: 'light', int: false}
    });
  },
  downloadFileFromBase64(response, title){
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', title)
    document.body.appendChild(link)
    link.click()
  },
  setSession(name, value){
    sessionStorage.setItem(this.getStorageName(name), JSON.stringify(value));
  },
  getSession(name){
    return JSON.parse(sessionStorage.getItem(this.getStorageName(name)));
  },
  delSession(name){
    return sessionStorage.removeItem(this.getStorageName(name));
  },
  setLocal(name, value){
    localStorage.setItem(this.getStorageName(name), JSON.stringify(value));
  },
  getLocal(name){
    return JSON.parse(localStorage.getItem(this.getStorageName(name)));
  },
  delLocal(name){
    return sessionStorage.removeItem(this.getStorageName(name));
  },
};