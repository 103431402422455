import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from "@/utils/constant";

Vue.use(VueRouter)

const app_name =  process.env['VUE_APP_NAME'];

const routes = [
  {
    path: '/',
    redirect: 'overview',
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: { layout: 'Content', auth: [], title: `Profile | ${app_name}` }
  },
  {
    path: '/overview',
    name: 'overview',
    component: () => import('@/views/overview/Index.vue'),
    meta: { layout: 'Content', auth: [], title: `Overview | ${app_name}` }
  },
  {
    path: '/create-quotation',
    name: 'create-quotation',
    component: () => import('@/components/overview/Quotaion'),
    meta: { layout: 'Content', auth: [Role.CLIENT], title: `Create - Quotation | ${app_name}` }
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('@/views/review/Index.vue'),
    meta: { layout: 'Content', auth: [Role.REVIEW], title: `Review | ${app_name}` }
  },
  {
    path: '/quotation-detail',
    component: () => import('@/components/overview/QuotationDetails.vue'),
    meta: { layout: 'Content', auth: [], title: `Quotation - Details | ${app_name}` }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/message/Index.vue'),
    meta: { layout: 'Content', auth: [], title: `Message | ${app_name}` }
  },
  {
    path: '/accepted',
    name: 'accepted',
    component: () => import('@/views/accepted/Index.vue'),
    meta: { layout: 'Content', auth: [Role.ACCEPTED], title: `Accepted | ${app_name}` }
  },
  {
    path: '/processing',
    name: 'processing',
    component: () => import('@/views/processing/Index.vue'),
    meta: { layout: 'Content', auth: [Role.PROCESSING], title: `Processing | ${app_name}` }
  },
  {
    path: '/delivered',
    name: 'delivered',
    component: () => import('@/views/delivered/Index.vue'),
    meta: { layout: 'Content', auth: [Role.DELIVERED], title: `Delivered | ${app_name}` }
  },
  {
    path: '/revision',
    name: 'revision',
    component: () => import('@/views/revision/Index.vue'),
    meta: { layout: 'Content', auth: [Role.REVISION], title: `Revision | ${app_name}` }
  },
  {
    path: '/completed',
    name: 'completed',
    component: () => import('@/views/completed/Index.vue'),
    meta: { layout: 'Content', auth: [Role.COMPLETED], title: `Completed | ${app_name}` }
  },
  {
    path: '/trash',
    name: 'trash',
    component: () => import('@/views/trash/Index.vue'),
    meta: { layout: 'Content', auth: [Role.TRASH], title: `Trash | ${app_name}` }
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/clients/Index.vue'),
    meta: { layout: 'Content', auth: [Role.CLIENTS], title: `Clients | ${app_name}` }
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/members/Index.vue'),
    meta: { layout: 'Content', auth: [Role.SUPER], title: `Members | ${app_name}` }
  },
  {
    path: '/signin',
    name: 'Sign-in',
    component: () => import('@/views/Login.vue'),
    meta: { layout: 'Blank', title: `Sign-in | ${app_name}` }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('@/views/Password.vue'),
    meta: { layout: 'Blank', title: `Password | ${app_name}` }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: { layout: 'Blank', title: `Forgot Password | ${app_name}` }
  },
  {
    path: "/reset-password/:token",
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: { layout: 'Blank', title: `Reset Password | ${app_name}` }
  },
  {
    path: '/register',
    name: 'Registration',
    component: () => import('@/views/Register.vue'),
    meta: { layout: 'Blank', title: `Registration | ${app_name}` }
  },
  {
    path: '/register-success',
    name: 'Registration Success',
    component: () => import('@/views/RegistrationSuccess.vue'),
    meta: { layout: 'Blank', title: `Registration Success | ${app_name}` }
  },
  {
    path: "/verify/:token",
    component: () => import('@/views/VerifyEmail.vue'),
    meta: { layout: 'Blank', title: `Account-Verify | ${app_name}` }
  },
  {
    path: "/setup-accounts",
    name: 'setup-accounts',
    component: () => import('@/views/CompleteRegister.vue'),
    meta: { auth: [], layout: 'Blank', title: `Account - Setup | ${app_name}` }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: { layout: 'Blank', title: `404 | ${app_name}` }
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function addDynamicPageTitle(to, from, next){
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle){
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta){
    document.title = previousNearestWithMeta.meta.title;
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    el => el.parentNode.removeChild(el)
  );

  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));
}

router.beforeEach((to, from, next) => {
  const { auth } = to.meta;
  const currentUser = Vue.tools.getSession('user');

  if(auth){
    if(!currentUser){
      return next({ path: '/signin' });
    }
    // checking user status
    const parsedJWTJson = Vue.tools.parseJwt(currentUser.access_token);
    if(parsedJWTJson["ust"] === "created" || parsedJWTJson["ast"] === "created"){
      if(to.path === '/setup-accounts') return next();
      next({path: '/setup-accounts'});
    } else if(parsedJWTJson["ust"] === "inactive" || parsedJWTJson["ast"] === "inactive"){
      next({path: '/error-404'});
    } else{
      next();
    }

    if(!Vue.tools.authorize(auth)){
      return next({ path: '/error-404' });
    }
  }

  // Function for adding dynamic page title
  addDynamicPageTitle(to, from, next);

  next();
});

export default router
