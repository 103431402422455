//LOGIN
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const REFRESHTOKEN = "REFRESHTOKEN";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const COMPLETE_REGISTRATION = "COMPLETE_REGISTRATION";

//USER
export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";
export const EDIT_USER_SETTINGS = "EDIT_USER_SETTINGS";
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const UPLOAD_USER_ID = "UPLOAD_USER_ID";
export const UPSERT_CHAT_ID = "UPSERT_CHAT_ID";
export const LOAD_CHAT_IDS = "LOAD_CHAT_IDS";

//Account
export const LOAD_ACCOUNT = "LOAD_ACCOUNT";
export const EDIT_ACCOUNT_SETTINGS = "EDIT_ACCOUNT_SETTINGS";

//PASSWORD RESET
export const SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK";
export const VERIFY_RESET_PASSWORD_LINK = "VERIFY_RESET_PASSWORD_LINK";
export const RESET_PASSWORD = "RESET_PASSWORD";

//ORGANIZATION
export const LOAD_ORGANIZATION = "LOAD_ORGANIZATION";

//ALERT
export const ADD_ALERT = "ADD_ALERT";

//LOADING
export const TOGGLE_LOADING = "TOGGLE_LOADING";

// UPLOAD
export const SAVE_CONTENT = "SAVE_CONTENT";
export const LOAD_ALL_CONTENT = "LOAD_ALL_CONTENT";
export const LOAD_SINGLE_CONTENT = "LOAD_SINGLE_CONTENT";
export const DOWNLOAD_FROM_URL = "DOWNLOAD_FROM_URL";
export const DOWNLOAD_AS_ZIP = "DOWNLOAD_AS_ZIP";

// QUOTATION
export const DELIVER_BY_FTP = "DELIVER_BY_FTP";
export const CREATE_QUOTATION = "CREATE_QUOTATION";
export const LOAD_ALL_QUOTATION = "LOAD_ALL_QUOTATION";
export const LOAD_CHART_QUOTATION = "LOAD_CHART_QUOTATION";
export const LOAD_QUOTATION_BY_ID = "LOAD_QUOTATION_BY_ID";
export const REQUEST_QUOTATION = "REQUEST_QUOTATION";
export const UPDATE_QUOTATION = "UPDATE_QUOTATION";
export const EDIT_FILE_NAME = "EDIT_FILE_NAME";
export const DELETE_FILE = "DELETE_FILE";

// MEMBERS
export const LOAD_ALL_MEMBERS = "LOAD_ALL_MEMBERS";
export const LOAD_MEMBER_BY_ID = "LOAD_MEMBER_BY_ID";
export const SEND_MEMBER_INVITATION = "SEND_MEMBER_INVITATION";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const MEMBER_SETUP = "MEMBER_SETUP";

// CLIENTS
export const LOAD_ALL_CLIENTS = "LOAD_ALL_CLIENTS";
export const LOAD_CLIENTS_BY_ID = "LOAD_CLIENTS_BY_ID";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";

// NOTIFICATIONS
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
