import Api from "@/services/api";
import {
  ADD_ALERT,
  LOAD_ALL_CONTENT,
  LOAD_SINGLE_CONTENT,
  SAVE_CONTENT,
  EDIT_FILE_NAME,
  DELETE_FILE,
  DOWNLOAD_FROM_URL,
  DOWNLOAD_AS_ZIP,
} from "@/store/_actiontypes";
import { SET_ALL_CONTENT, SET_CONTENT } from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allContent: [],
  content: "",
};

const actions = {
  [LOAD_ALL_CONTENT]({ commit }, {get_from, path, content_type}){
    updateLoaderTo(true);
    commit(SET_ALL_CONTENT, []);
    return new Promise((resolve, reject) => {
      Api.get(`contents/list/${path}?get_from=${get_from}&content_type=${content_type}`).then(
        (response) => {
          let allContent = response.data.details;
          commit(SET_ALL_CONTENT, allContent);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [LOAD_SINGLE_CONTENT]({ commit }, { content_key }){
    updateLoaderTo(true);
    commit(SET_CONTENT, "");
    return new Promise((resolve, reject) => {
      Api.get(`contents/single?content_key=${content_key}`).then(
        (response) => {
          let content = response.data.url;
          commit(SET_CONTENT, content);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [SAVE_CONTENT]({ commit }, { save_to, path, content_type, formData, onUploadProgress }){
    updateLoaderTo(true);

    let file = new FormData();
    file.append("file", formData)
    file.append("save_to", save_to)
    file.append("content_type", content_type)

    return new Promise((resolve, reject) => {
      Api.post(`${path}`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [EDIT_FILE_NAME]({ commit, dispatch }, {from, old_name, new_name, path, content_type}){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`contents/edit/${path}`, {
        from: from,
        old_name: old_name,
        new_name: new_name,
        content_type: content_type
      }).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [DELETE_FILE]({ commit, dispatch }, {from, name, path, content_type}){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`contents/delete/${path}/?from=${from}&name=${name}&content_type=${content_type}`).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [DOWNLOAD_FROM_URL]({ commit, dispatch }, {path}){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`contents/download/${path}`).then((response) => {
        updateLoaderTo(false);
        const buffer = Buffer.from(response.data.filedata, 'base64');
        resolve(buffer);
      },
      (error) => {
        updateLoaderTo(false);
        reject(error);
      }
    )})
  },

  [DOWNLOAD_AS_ZIP]({ commit, dispatch }, {job_id, status}){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/contents/create_zip/${job_id}/${status}`).then((response) => {
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: response.data.message, color: "success" },
          { root: true }
        );
        updateLoaderTo(false);
        resolve(response);
      },
      (error) => {
        updateLoaderTo(false);
        reject(error);
      }
    )})
  },

};

const mutations = {
  [SET_ALL_CONTENT](state, allContent){
    state.allContent = allContent;
  },
  [SET_CONTENT](state, content){
    state.content = content;
  }
};

export const upload = {
  namespaced: true,
  state,
  actions,
  mutations,
};
