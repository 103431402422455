//LOGIN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

// ERROR
export const SET_ERROR = "SET_ERROR";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_SIGNUP_ERROR = "SET_SIGNUP_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

//USER
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const SET_CHAT_IDS = "SET_CHAT_IDS";

//Account
export const SET_ACCOUNT = "SET_ACCOUNT";

// RESET PASSWORD
export const SET_RESET_PASSWORD_USER_DETAILS = "SET_RESET_PASSWORD_USER_DETAILS";

//ORGANIZATION
export const SET_ORGANIZATION = "SET_ORGANIZATION";

//CURRENCIES
export const SET_CURRENCIES = "SET_CURRENCIES";

//ALERT
export const CREATE_ALERT = "CREATE_ALERT";

//LOADING
export const UPDATE_LOADING = "UPDATE_LOADING";

//PROJECTS
export const SET_ALL_PROJECT = "SET_ALL_PROJECT";
export const SET_PROJECT_BY_ID = "SET_PROJECT_BY_ID";
export const SET_PROJECT_COST = "SET_PROJECT_COST";
export const SET_ALL_CREDENTIAL = "SET_ALL_CREDENTIAL";

// UPLOAD
export const SET_ALL_CONTENT = "SET_ALL_CONTENT";
export const SET_CONTENT = "SET_CONTENT";


// QUOTATION
export const SET_ALL_QUOTATION = "SET_ALL_QUOTATION";
export const SET_QUOTATION_BY_ID = "SET_QUOTATION_BY_ID";
export const SET_CHART_QUOTATION = "SET_CHART_QUOTATION";
export const SET_QUOTATION = "SET_QUOTATION";

// MEMBERS
export const SET_ALL_MEMBERS = "SET_ALL_MEMBERS";
export const SET_MEMBER_BY_ID = "SET_MEMBER_BY_ID";

// CLIENTS
export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";
export const SET_CLIENTS_BY_ID = "SET_CLIENTS_BY_ID";

// NOTIFICATIONS
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
