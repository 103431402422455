import axios from "axios";
import store from "@/store";

import { ADD_ALERT, REFRESHTOKEN, TOGGLE_LOADING } from "@/store/_actiontypes";
import { CLEAR_ERROR } from "@/store/_mutationtypes";

import tools from '@/utils/tools'
import router from "@/router/index";

let isRefreshingToken = false;
let callbacks = [];

let api = axios.create({
  baseURL: tools.getEnv("VUE_APP_BACKEND_API")
});

api.interceptors.request.use(
  (request) => {
    // add authorization header with jwt token to each request
    let user = tools.getSession("user");

    request.headers["X-Client-Id"] = tools.getEnv("VUE_APP_CLIENT_ID")
    request.headers["X-Client-Secret"] = tools.getEnv("VUE_APP_CLIENT_SECRET")

    if(user && request.url === "oauth2/refresh"){
      request.headers["Authorization"] = `Bearer ${user.refresh_token}`;
    } else if(user){
      request.headers["Authorization"] = `Bearer ${user.access_token}`;
    }

    updateLoaderTo(true);
    return request;
  }
);

api.interceptors.response.use(
  (response) => {
    updateLoaderTo(false);
    store.commit(`account/${CLEAR_ERROR}`);

    return response;
  },
  (error) => {
    updateLoaderTo(false);
    var errormessage = error.response && error.response.data.message ? error.response.data.message : error.message;
    if(error.response && error.response.status === 422){
      if(error.response.data.errors){
        errormessage = "";
        error.response.data.errors.forEach((value) => {
          errormessage += value.toString() + " ";
        });
      }
    } else if(error.response && error.response.status === 401){
      let user = tools.getSession("user");
      if(user && user.refresh_token){
        const originalRequest = error.config;
        if(!isRefreshingToken){
          isRefreshingToken = true;
          store.dispatch(
              `account/${REFRESHTOKEN}`,
              { refresh_token: user.refresh_token },
              { root: true }
            ).then(() => {
              isRefreshingToken = false;
              tokenRefreshed();
            }).catch(() => {
              router.push("/signin");
            });
        } else {
          tools.delSession("user");
        }

        return new Promise((resolve) => {
          addCallback(() => {
            originalRequest.headers.Authorization = `Bearer ${store.state.account.user.access_token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }

    errormessage = errormessage === "Request aborted" ? "" : errormessage === "The token has expired." ? "" : errormessage === "The token has been revoked." ? "" : errormessage;
    if(errormessage){
      store.dispatch(
        `alert/${ADD_ALERT}`,
        { message: errormessage, color: "error" },
        { root: true }
      );
    }

    return Promise.reject(error);
  }
);

let updateLoaderTo = (loading) => {
  store.dispatch(`loader/${TOGGLE_LOADING}`,{loading: loading},{root: true});
};

let tokenRefreshed = () => {
  callbacks = callbacks.filter((callback) => callback());
};

let addCallback = (callback) => {
  callbacks.push(callback);
};

export default api;
