const localUser = JSON.parse(sessionStorage.getItem("doers_user"));

import store from "@/store";
import Api from "@/services/api";
import router from "@/router/index";
import tools from '@/utils/tools'
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  REFRESHTOKEN,
  EDIT_USER_DETAILS,
  EDIT_USER_SETTINGS,
  EDIT_USER_PROFILE,
  ADD_ALERT,
  LOAD_ORGANIZATION,
  TOGGLE_LOADING,
  SEND_PASSWORD_RESET_LINK,
  VERIFY_RESET_PASSWORD_LINK,
  RESET_PASSWORD,
  CHANGE_USER_PASSWORD,
  VERIFY_EMAIL,
  COMPLETE_REGISTRATION,
  UPSERT_CHAT_ID,
  LOAD_ACCOUNT,
  EDIT_ACCOUNT_SETTINGS,
  UPLOAD_USER_ID,
  LOAD_CHAT_IDS,
} from "@/store/_actiontypes";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_USER,
  UPDATE_USER_DETAILS,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_PROFILE,
  SET_CURRENCIES,
  SET_ORGANIZATION,
  CLEAR_ERROR,
  SET_LOGIN_ERROR,
  SET_SIGNUP_ERROR,
  SET_RESET_PASSWORD_USER_DETAILS,
  SET_ACCOUNT,
  SET_CHAT_IDS,
} from "@/store/_mutationtypes";

const state = {
  user: localUser ? localUser : null,
  currencies: [],
  organization: {},
  login_error: null,
  signup_error: null,
  resetPasswordUserDetails: {},
  account: {},
  chatIds: [],
};

const actions = {
  [LOGIN]({ dispatch, commit }, { email, password, recaptcha_token }){
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post("oauth2/access",
        {
          email: email,
          password: password
        },
        {
          headers: {
            'X-Captcha-Token': recaptcha_token
          }
        }
      ).then((response) => {
          updateLoaderTo(false);
          let user = response.data;
          commit(LOGIN_SUCCESS, user);
          resolve(response);
        }, (error) => {
          let login_error = error.response.data.message;
          commit(SET_LOGIN_ERROR, login_error);
          commit(LOGIN_FAILURE);
          updateLoaderTo(false);
          reject(error);
        }
      ).catch(() => {})
    });
  },

  [REGISTER]({commit, dispatch}, {name,email,first_name,last_name,password,recaptcha_token}){
    commit(CLEAR_ERROR);
    updateLoaderTo(true);

    return new Promise((resolve, reject) => {
      Api.post("accounts/register",
        {
          account_name: name,
          email: email,
          first_name: first_name,
          last_name: last_name,
          password: password
        },
        {
          headers: {
            'X-Captcha-Token': recaptcha_token
          }
        }
      ).then((response) => {
          updateLoaderTo(false);
          resolve(response);
        },(error) => {
          commit(SET_SIGNUP_ERROR, error.response.data.message);
          updateLoaderTo(false);
          reject(error);
        }).catch((error) => {
          commit(SET_SIGNUP_ERROR, error.response.data.message);
          updateLoaderTo(false);
        });
    });
  },

  [REFRESHTOKEN]({ commit, dispatch }, { refresh_token }){
    return new Promise((resolve, reject) => {
      Api.post("oauth2/refresh", { refresh_token, }).then((response) => {
        let user = {
          access_token: response.data.access_token,
          refresh_token: refresh_token,
          expires_at: response.data.expires_at,
        }
        commit(LOGIN_SUCCESS, user);
        resolve(response);
      }, (error) => {
        commit(LOGIN_FAILURE);
        reject(error);
      }
    )}
  )},

  [LOGOUT]({ commit }, { refresh_token }){
    return Api.post("oauth2/revoke", {
      refresh_token
    }).then((response) => {
      commit(LOGOUT_USER);
    }).catch((e) => {});
  },

  [VERIFY_EMAIL]({ commit, dispatch }, { token, recaptcha_token }){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`accounts/${token}/verify`, {}, {
        headers: {
          'X-Captcha-Token': recaptcha_token
        }
      }).then((response) => {
        updateLoaderTo(false);
        let jsonResponse = response.data;
        commit(LOGIN_SUCCESS, jsonResponse);
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: jsonResponse.message, color: "success" },
          { root: true }
        );
        resolve(response);
      },(error) => {
        updateLoaderTo(false);
        reject(error);
      });
    });
  },

  //let's add account setup endpoint here in front end complete registration
  [COMPLETE_REGISTRATION]({ commit, dispatch, state }, { userData }){
    return new Promise((resolve, reject) => {
      Api.put("accounts/setup", {
        account_name: userData.account_name,
        type: userData.type,
        first_name: userData.first_name,
        last_name: userData.last_name,
        birth_date: userData.birth_date,
        gender: userData.gender,
        phone: userData.phone,
        photo: userData.photo,
        ip_address: userData.ip_address,
        country_code: userData.country_code,
        state_or_province: userData.state_or_province,
        city: userData.city,
        postal_code: userData.postal_code,
        address: userData.address
      }).then((response) => {
        updateLoaderTo(false);
        dispatch(REFRESHTOKEN, {
          refresh_token: state.user.refresh_token
        }).then(res => {
          router.push("/overview?complete=true");
        }).catch(err => {})
        resolve(response)
      }).catch((error) => {
        updateLoaderTo(false);
        reject(error)
      })
    })
  },

  [UPSERT_CHAT_ID]({ commit, dispatch }, { chat_id }){
    return Api.put("users/chat", {
      chat_id: chat_id
    }).then((response) => {
      console.log("chat-id upsert")
    });
  },

  [EDIT_USER_DETAILS]({ commit }){
    commit(UPDATE_USER_DETAILS);
  },

  [EDIT_USER_SETTINGS]({ commit, dispatch }, { systemName, currencyRegionName, useDarkMode }){
    return Api.put("settings", {
      systemName,
      currencyRegionName,
      useDarkMode,
    }).then((response) => {
      commit(UPDATE_USER_SETTINGS, response.data);
      dispatch(
        `alert/${ADD_ALERT}`,
        { message: "Settings updaded successfully", color: "success" },
        { root: true }
      );
    });
  },

  // update user account data from '/profile' route
  [EDIT_USER_PROFILE]({ commit, dispatch }, { userData }){
    return new Promise((resolve, reject) => {
      Api.put("users/me", {
        first_name: userData.first_name,
        middle_name: userData.middle_name,
        last_name: userData.last_name,
        birth_date: userData.birth_date,
        gender: userData.gender,
        phone: userData.phone,
        photo: userData.photo,
        country_code: userData.country_code,
        state_or_province: userData.state_or_province,
        city: userData.city,
        postal_code: userData.postal_code,
        address: userData.address
      }).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // upload id
  [UPLOAD_USER_ID]({ commit, dispatch }, { userData }){
    return new Promise((resolve, reject) => {
      Api.post("users/identities", {
        photo_id_front: userData.photo_id_front,
        photo_id_back: userData.photo_id_back,
      }).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // update user account data from '/settings' route
  [EDIT_ACCOUNT_SETTINGS]({ commit, dispatch }, { userData }){
    return new Promise((resolve, reject) => {
      Api.put("accounts", {
        account_name: userData.account_name,
        type: userData.type,
        org_name: userData.org_name,
        org_size: userData.org_size,
        org_email: userData.org_email,
        org_phone: userData.org_phone,
        org_logo: userData.org_logo,
        org_industry_id: userData.org_industry_id,
        org_website_url: userData.org_website_url,
        org_vat_number: userData.org_vat_number,
        org_registration_number: userData.org_registration_number,
        org_director_name: userData.org_director_name,
        org_address: userData.org_address,
      }).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // Load all the account settings data
  [LOAD_ACCOUNT]({ commit }){
    return new Promise((resolve, reject) => {
      Api.get("accounts").then((response) => {
        commit(SET_ACCOUNT, response.data);
        resolve(response)
      }).catch((error) => {
        resolve(error)
      })
    })
  },

  [LOAD_ORGANIZATION]({ commit }){
    return new Promise((resolve, reject) => {
      Api.get("users/me").then((response) => {
        commit(SET_ORGANIZATION, response.data);
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [LOAD_CHAT_IDS]({ commit }, {cid: cid}){
    return new Promise((resolve, reject) => {
      Api.get(`users/chat_ids${cid?`?user_id=${cid}`:""}`).then((response) => {
        commit(SET_CHAT_IDS, response.data.items);
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [SEND_PASSWORD_RESET_LINK]({ commit, dispatch }, { email, reset_page_route, recaptcha_token }){
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`users/passwords/${email}/forgot`,
        {
          reset_page_route: reset_page_route,
          recaptcha_token: recaptcha_token
        },
        {
          headers: {
            'X-Captcha-Token': recaptcha_token
          }
        }
      ).then((response) => {
        updateLoaderTo(false);
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: response.data.message, color: "success" },
          { root: true }
        );
        resolve(response);
      },(error) => {
        updateLoaderTo(false);
        reject(error);
      })
      .catch((error) => {});
    });
  },

  [VERIFY_RESET_PASSWORD_LINK]({ commit }, { token }){
    updateLoaderTo(true);
    commit(SET_RESET_PASSWORD_USER_DETAILS, {});
    return new Promise((resolve, reject) => {
      Api.get(`users/passwords/${token}/reset`).then((response) => {
        updateLoaderTo(false);
        if(response.data){
          commit(SET_RESET_PASSWORD_USER_DETAILS, response.data);
        }
        resolve(response);
      },(error) => {
        updateLoaderTo(false);
        reject(error);
      });
    });
  },

  [RESET_PASSWORD]({ commit, dispatch }, { token, password, recaptcha_token }){
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`users/passwords/${token}/reset`,
        {
          password: password
        },
        {
          headers: {
            'X-Captcha-Token': recaptcha_token
          }
        }
      ).then((response) => {
        updateLoaderTo(false);
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: response.data.message, color: "success" },
          { root: true }
        );
        resolve(response);
        router.push("/signin");
      },(error) => {
        updateLoaderTo(false);
        reject(error);
      })
      .catch((error) => {});
    });
  },

  [CHANGE_USER_PASSWORD]({ commit, dispatch }, { old_password, new_password }){
    return new Promise((resolve, reject) => {
      Api.put(`users/passwords`,{
        old_password: old_password,
        new_password: new_password
      }).then(response => {
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: response.data.message, color: "success" },
          { root: true }
        );
        commit(LOGOUT_USER)
        router.push("/signin");
        resolve(response);
      }, error => {
        reject(error);
      })
    })
  },

};

const mutations = {
  [LOGIN_SUCCESS](state, user){
    // login successful if there's a jwt token in the response
    if(user.access_token){
      tools.setSession("user", user);
    }
    state.user = user;
  },
  [LOGIN_FAILURE](state){
    state.user = null;
    tools.delSession("user");
  },
  [LOGOUT_USER](state){
    // remove user from local storage
    tools.delSession("user");
    tools.delSession('chat');
    state.user = null;
  },
  [UPDATE_USER_DETAILS](state){
    state.user.useDarkMode = !state.user.useDarkMode;
  },
  [UPDATE_USER_SETTINGS](
    state,
    { systemName, currencyRegionName, useDarkMode, theme, displayCurrency }
  ){
    state.user.systemName = systemName;
    state.user.useDarkMode = useDarkMode;
    state.user.theme = theme;
    state.user.currencyRegionName = currencyRegionName;
    state.user.displayCurrency = displayCurrency;
  },
  [UPDATE_USER_PROFILE](state, { firstName, lastName, fullName }){
    state.user.firstName = firstName;
    state.user.lastName = lastName;
    state.user.fullName = fullName;
  },
  [SET_CURRENCIES](state, currencies){
    state.currencies = currencies;
  },
  [SET_ORGANIZATION](state, organization){
    state.organization = organization;
  },
  [SET_LOGIN_ERROR](state, login_error){
    state.login_error = login_error;
    state.signup_error = null;
  },
  [SET_SIGNUP_ERROR](state, payload){
    state.login_error = null;
    state.signup_error = payload;
  },
  [CLEAR_ERROR](state){
    state.login_error = null;
    state.signup_error = null;
  },
  [SET_RESET_PASSWORD_USER_DETAILS](state, resetPasswordUserDetails){
    state.resetPasswordUserDetails = resetPasswordUserDetails;
  },
  [SET_ACCOUNT](state, account){
    state.account = account;
  },
  [SET_CHAT_IDS](state, ids){
    state.chatIds = ids;
  },
};

const getters = {
  nameInitials: (state) => {
    var initials = state.user.fullName.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  },
  nameFirstCharacter: (state) => {
    if(state.organization.status === "created"){
      return state.account.account_name && state.account.account_name.charAt(0).toUpperCase();
    }else if (state.organization.status === "active"){
      return state.organization.first_name && state.organization.first_name.charAt(0).toUpperCase();
    }
    return "H";
  },
};

export let updateLoaderTo = (loading) => {
  store.dispatch(
    `loader/${TOGGLE_LOADING}`,
    { loading: loading },
    { root: true }
  );
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
