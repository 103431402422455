import Api from "@/services/api";
import {
  LOAD_NOTIFICATIONS,
  READ_NOTIFICATION,
} from "@/store/_actiontypes";
import { SET_NOTIFICATIONS } from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  all: []
};

const actions = {

  [LOAD_NOTIFICATIONS]({ commit }){
    return new Promise((resolve, reject) => {
      Api.get("notifications").then((response) => {
        commit(SET_NOTIFICATIONS, response.data.items);
        resolve(response)
      }).catch((error) => {
        resolve(error)
      })
    })
  },

  [READ_NOTIFICATION]({commit, dispatch}, {id}){
    return new Promise((resolve, reject) => {
      updateLoaderTo(true);
      Api.put(`/notifications/read/${id}`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

}

const mutations = {
  [SET_NOTIFICATIONS](state, data){
    state.all = data;
  }
};

export const notification = {
  namespaced: true,
  state,
  actions,
  mutations,
};
