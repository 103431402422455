import Api from "@/services/api";
import {
  ADD_ALERT,
  DELIVER_BY_FTP,
  CREATE_QUOTATION,
  LOAD_ALL_QUOTATION,
  LOAD_CHART_QUOTATION,
  LOAD_QUOTATION_BY_ID,
  REQUEST_QUOTATION,
  UPDATE_QUOTATION
} from "@/store/_actiontypes";
import {
  SET_QUOTATION,
  SET_ALL_QUOTATION,
  SET_CHART_QUOTATION,
  SET_QUOTATION_BY_ID
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  all: {},
  data: {},
  details: {},
  chart_data: {},
};

const actions = {
  [CREATE_QUOTATION]({ commit }){
    return new Promise((resolve, reject) => {
      Api.get("quotations/create").then((response) => {
        commit(SET_QUOTATION, response.data);
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  [REQUEST_QUOTATION]({commit, dispatch}, {quotationId, type, data}){
    return new Promise((resolve, reject) => {
      let payload = {
        job_name: data.job_name,
        instructions: data.instructions,
        adv_instructions: data.adv_instructions,
        custom_instructions: data.custom_instructions,
        buyer_amount: data.buyer_amount,
        file_info: data.file_info,
        revision: data.revision,
        upload_via: data.upload_via,
        status: data.status
      };
      
      updateLoaderTo(true);
      Api.put(`/quotations/request/${quotationId}/${type}`, payload).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [UPDATE_QUOTATION]({commit, dispatch}, {quotationId, type, data}){
    return new Promise((resolve, reject) => {
      let endpoint = "";
      let payload = {};

      if(type == "requested"){
        endpoint = `/quotations/update/${quotationId}/${type}`;
        payload = {file_info: data.file_info};

      } else if(type == "review"){
        endpoint = `/quotations/review/${quotationId}`;
        payload = data;
      } else if(type == "accepted"){
        endpoint = `/quotations/accept/${quotationId}`;
        payload = {file_info: data.file_info};
      } else if(type == "todo"){
        endpoint = `/quotations/todo/${quotationId}`;
        payload = data;
      } else if(type == "delivered"){
        endpoint = `/quotations/delivered/${quotationId}`;
        payload = data;
      } else if(type == "completed"){
        endpoint = `/quotations/completed/${quotationId}`;
        payload = data;
      } else if(type == "revision"){
        endpoint = `/quotations/revision/${quotationId}`;
        payload = {file_info: data};
      }
      
      updateLoaderTo(true);
      Api.put(endpoint, payload).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [DELIVER_BY_FTP]({ commit }, {job_id}){
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`quotations/delivered/${job_id}/byftp`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [LOAD_QUOTATION_BY_ID]({ commit }, { quotation_id }){
    updateLoaderTo(true)
    commit(SET_QUOTATION_BY_ID, {})
    return new Promise((resolve, reject) => {
      Api.get(`quotations/detail/${quotation_id}`).then(
        response => {
          commit(SET_QUOTATION_BY_ID, response.data)
          updateLoaderTo(false)
          resolve(response)
        },
        error => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },

  [LOAD_ALL_QUOTATION]({ commit }, {start_date, end_date, job_status, per_page, page}){
    updateLoaderTo(true);
    commit(SET_ALL_QUOTATION, {});
    return new Promise((resolve, reject) => {
      Api.get(`/quotations/list?${start_date?`start_date=${start_date}`:""}${end_date?`&end_date=${end_date}`:""}${page?`&page=${page}`:""}&per_page=${per_page?per_page:100}${job_status?`&status=${job_status}`:""}`).then(
        (response) => {
          commit(SET_ALL_QUOTATION, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [LOAD_CHART_QUOTATION]({ commit }, {start_date, end_date, status}){
    updateLoaderTo(true);
    commit(SET_CHART_QUOTATION, {});
    return new Promise((resolve, reject) => {
      Api.get(`/quotations/chart?${status?`status=${status}`:""}${start_date?`&start_date=${start_date}`:""}${end_date?`&end_date=${end_date}`:""}`).then(
        (response) => {
          commit(SET_CHART_QUOTATION, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

};

const mutations = {
  [SET_QUOTATION](state, data){
    state.data = data;
  },
  [SET_ALL_QUOTATION](state, all){
    state.all = all;
  },
  [SET_CHART_QUOTATION](state, data){
    state.chart_data = data;
  },
  [SET_QUOTATION_BY_ID](state, data){
    state.details = data;
  }
};

export const quotation = {
  namespaced: true,
  state,
  actions,
  mutations,
};