import Api from "@/services/api";
import {
  ADD_ALERT,
  LOAD_ALL_CLIENTS,
  LOAD_CLIENTS_BY_ID,
  UPDATE_CLIENTS
} from "@/store/_actiontypes";
import {
  SET_ALL_CLIENTS,
  SET_CLIENTS_BY_ID,
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  all: {},
  data: {},
  details: {}
};

const actions = {

  [LOAD_CLIENTS_BY_ID]({ commit }, { client_id }){
    updateLoaderTo(true)
    commit(SET_CLIENTS_BY_ID, {})
    return new Promise((resolve, reject) => {
      Api.get(`accounts/detail/${client_id}`).then(
        response => {
          commit(SET_CLIENTS_BY_ID, response.data)
          updateLoaderTo(false)
          resolve(response)
        },
        error => {
          updateLoaderTo(false)
          reject(error)
        }
      )
    })
  },

  [LOAD_ALL_CLIENTS]({ commit }, {start_date, end_date, job_status, per_page, page}){
    updateLoaderTo(true);
    commit(SET_ALL_CLIENTS, {});
    return new Promise((resolve, reject) => {
      Api.get(`/accounts/list?${start_date?`start_date=${start_date}`:""}${end_date?`&end_date=${end_date}`:""}${page?`&page=${page}`:""}&per_page=${per_page?per_page:100}${job_status?`&status=${job_status}`:""}`).then(
        (response) => {
          commit(SET_ALL_CLIENTS, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [UPDATE_CLIENTS]({commit, dispatch}, {account_id, status}){
    return new Promise((resolve, reject) => {
      updateLoaderTo(true);
      Api.put(`/accounts/status/${account_id}`, {status: status}).then(
        (response) => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true }
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  
};

const mutations = {
  [SET_ALL_CLIENTS](state, all){
    state.all = all;
  },
  [SET_CLIENTS_BY_ID](state, details){
    state.details = details;
  }
};

export const clients = {
  namespaced: true,
  state,
  actions,
  mutations,
};
